<template>
    <div class="back-card">
        <Toast />
        <div
            @dragenter.prevent="toggle"
            @dragleave.prevent="toggle"
            @dragover.prevent
            @drop.prevent="drop"
            @change="selectDocument"
            class="dropzone"
        >         
            <label for="filesback">
                <img v-if="hasFile" src="../../../../../assets/images/accounts/back_accept.png" alt="" />
                <img v-else src="../../../../../assets/images/accounts/back.png" alt="" />
                <div class="file-name">
                    <p class="text" v-if="hasFile">
                        {{ files.name }}
                    </p>
                    <p class="text" v-else>Verso</p>
                </div>
            </label>   
            <input
              type="file"
              id="filesback"
              class="dropzoneFile"
              accept=".png, .jpeg, .jpg, .png, .pdf"
            />
            <img 
                v-if="hasFile" 
                class="accept" 
                src="../../../../../assets/images/accounts/accept.png"
                alt=""
            />
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import { useToast } from "primevue/usetoast";
import axios from "axios";

const token = localStorage.getItem("token");

export default {
    props: ["modelValue", "msgErros"],
    emits: ["update:modelValue", "saveUrl"],
    setup(props, { emit }) {
        const files = ref("");
        const hasFile = ref(false);
        const active = ref(false);
        const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
        const docTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
        const toast = useToast();

        const toggle = () => {
            active.value = !active.value;
        };

        const maxSizeFile = (number) => {
            if (number < 1024) {
                return true;
            } else if (number >= 1024 && number <= 50000000) {
                return true;
            } else if (number > 50000000) {
                return false;
            }
        };

        const drop = async (e) => {
            // console.log('dropBack')
            active.value = !active.value;
            files.value = e.dataTransfer.files[0];
            const type = docTypes.includes(files.value.type);

            const maxSize = maxSizeFile(files.value.size);

            if (maxSize && type) {
                const result = await saveFile(files.value, "document_back");
                if (result) {
                    hasFile.value = result;
                } else {
                    files.value = "";
                    toast.add({
                        severity: "error",
                        summary: "Atenção!",
                        detail: "Erro ao gravar o arquivo no sistema",
                        life: 3000,
                    });
                }
            } else {
                files.value = "";
                toast.add({
                    severity: "error",
                    summary: "Atenção!",
                    detail: "Arquivo inválido",
                    life: 3000,
                });
            }
        };

        const selectDocument = async (e) => {
            // console.log('selectDocumentBack')
            active.value = !active.value;

            if (!e.target.files) {
                files.value = files.value;
            } else {
                files.value = e.target.files[0];
                const type = docTypes.includes(files.value.type);
                // console.log("Tipo", type);
                const maxSize = maxSizeFile(files.value.size);

                if (maxSize && type) {
                    const result = await saveFile(files.value, "document_back");
                    if (result) {
                        active.value = !active.value;
                        hasFile.value = result;
                    } else {
                        active.value = !active.value;
                        files.value[0] = "";
                        toast.add({
                            severity: "error",
                            summary: "Atenção!",
                            detail: "Erro ao gravar o arquivo no sistema",
                            life: 3000,
                        });
                    }
                } else {

                    files.value[0] = "";
                    toast.add({
                        severity: "error",
                        summary: "Atenção!",
                        detail: "Arquivo inválido",
                        life: 3000,
                    });
                }
            }
        };

        const saveFile = async (file, type) => {
            // console.log('saveFileBack')
            const formData = new FormData();
            formData.append("file", file);
            const url = process.env.VUE_APP_FILE_API_URL + "/professional/" + type;
            
            const response = await axios.post(url, formData, {
                headers: {
                    "Access-Control": "Allow-Origin",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + token,
                },
            });

            console.log(response);

            if (response.status == 200) {
                emit("saveUrl", response.data.url, 'back')
                return true;
            } else {
                return false;
            }
        };

        // watch(() => files.value, (newValue, oldValue) => {
        //     console.log('files mudou!', newValue)
        // });

        return{
            files,
            toggle,
            drop,
            selectDocument,
            hasFile,
        }
    },
};
</script>


<style lang="scss" scoped>
.back-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% / 2);
    font-family: "Roboto", sans-serif;
    cursor: pointer !important;
    .accept{
        position: absolute;
        top: 0;
    }
    .text {
        font-size: 14px;
        line-height: 22px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        color: #828282;
        text-align: center;
        margin: 0 auto;
        margin-top: -20px;
    }
    .dropzone {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        transition: 0.3s ease all;
        label {
            // padding: 8px 12px;
            // color: #fff;
            // background-color: #FF6A33;
            transition: 0.3s ease all;
            border-radius: 8px;
            cursor: pointer;
        }
        input {
            display: none;
        }
    }
}
</style>